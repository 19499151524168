import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../utils/supabase';
import './Dashboard.css';

const API_URL = 'https://roblox-game-data-api.vercel.app/api/games';

const EditProductForm = ({ product, onUpdate, onCancel }) => {
  const [title, setTitle] = useState(product.title);
  const [description, setDescription] = useState(product.description);
  const [price, setPrice] = useState(product.price);
  const [image, setImage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let updatedImageUrl = product.product_image_url;

    if (image) {
      const fileExt = image.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const { error: uploadError } = await supabase.storage
        .from('product-images')
        .upload(fileName, image);

      if (uploadError) {
        console.error('Error uploading new image:', uploadError);
        return;
      }

      const { data: publicUrlData } = supabase.storage
        .from('product-images')
        .getPublicUrl(fileName);

      updatedImageUrl = publicUrlData.publicUrl;
    }

    const updatedProduct = {
      ...product,
      title,
      description,
      price: parseFloat(price),
      product_image_url: updatedImageUrl
    };
    onUpdate(updatedProduct, image ? product.product_image_url : null);
  };


  return (
    <form onSubmit={handleSubmit} className="edit-product-form">
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Title"
        required
      />
      <textarea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Description"
        required
      />
      <input
        type="number"
        value={price}
        onChange={(e) => setPrice(e.target.value)}
        placeholder="Price"
        step="0.01"
        required
      />
      <input
        type="file"
        onChange={(e) => setImage(e.target.files[0])}
        accept="image/*"
      />
      <div className="button-group">
        <button type="submit">Save</button>
        <button type="button" onClick={onCancel}>Cancel</button>
      </div>
    </form>
  );
};


function Dashboard() {
  const navigate = useNavigate();
  const [games, setGames] = useState([]);
  const [isGamesOpen, setIsGamesOpen] = useState(true);
  const [products, setProducts] = useState([]);
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [productToDelete, setProductToDelete] = useState(null);
  const [seller, setSeller] = useState(null);

  const [stripeUserId, setStripeUserId] = useState(null);

  useEffect(() => {
    fetchGames();
    fetchProducts();
    checkSellerStatus();
  }, []);

  const checkSellerStatus = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (session) {
      const { data: sellerData, error } = await supabase
        .from('sellers')
        .select('onboarding_status, paypal_merchant_id')
        .eq('email', session.user.email)
        .single();

      if (!error) {
        setSeller(sellerData);
      }
    }
  };

  const fetchGames = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data: gamesData, error } = await supabase
        .from('game_data')
        .select('*')
        .eq('user_uid', user.id);
      
      const { data: userData, error: userError } = await supabase
        .from('phrases')
        .select('stripe_user_id')
        .eq('user_uid', user.id)
        .single();

      if (error) {
        console.error('Error fetching games:', error);
      } else if (gamesData) {
        setGames(gamesData);
      }

      if (userError) {
        console.error('Error fetching user data:', userError);
      } else if (userData) {
        setStripeUserId(userData.stripe_user_id);
      }
    }
  };

  const fetchProducts = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data, error } = await supabase
        .from('sellables')
        .select(`
          *,
          game_data (
            game_info
          )
        `)
        .eq('user_id', user.id);
      
      if (error) {
        console.error('Error fetching products:', error);
      } else {
        setProducts(data.map(product => ({
          ...product,
          game_name: product.game_data?.game_info?.name || 'Unknown Game'
        })));
      }
    }
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate('/login');
  };

  const handleAddGame = () => {
    navigate('/add-game');
  };

  const toggleGames = () => {
    setIsGamesOpen(!isGamesOpen);
  };

  const handleRefreshDetails = async (gameId) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        throw new Error('No active session found');
      }

      const { data: gameData, error } = await supabase
        .from('game_data')
        .select('game_id, universe_id')
        .eq('id', gameId)
        .single();

      if (error) throw error;

      const response = await fetch(`${API_URL}?identifier=${gameData.universe_id}`, {
        headers: {
          'Authorization': session.user.id
        }
      });


      if (!response.ok) {
        throw new Error(`Failed to fetch updated game details: ${response.status} ${response.statusText}`);
      }

      const updatedGameInfo = await response.json();

      const { data: updatedData, error: updateError } = await supabase
        .from('game_data')
        .update({
          game_info: updatedGameInfo.game_info,
          gamepasses: updatedGameInfo.gamepasses,
          updated_at: new Date().toISOString(),
        })
        .eq('id', gameId)
        .select()
        .single();

      if (updateError) throw updateError;

      setGames(games.map(game => 
        game.id === gameId ? { ...game, ...updatedData } : game
      ));


      console.log('Game info updated successfully:', updatedData);

    } catch (error) {
      console.error('Error refreshing game details:', error);
      // You might want to show an error message to the user here
    }
  };

  const truncateDescription = (description, maxLength = 50) => {
    if (!description) return 'No description available';
    if (description.length <= maxLength) return description;
    return description.substr(0, description.lastIndexOf(' ', maxLength)) + '...';
  };

  const handleSellProducts = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    
    if (!session) {
      const currentUrl = window.location.href;
      window.location.href = `${process.env.REACT_APP_AUTH_URL}/login?redirect=${encodeURIComponent(currentUrl)}`;
      return;
    }

    try {
      const { data: seller, error } = await supabase
        .from('sellers')
        .select('onboarding_status, paypal_merchant_id')
        .eq('email', session.user.email)
        .maybeSingle()
        .throwOnError();

      if (error) {
        console.error('Error checking seller status:', error);
        const onboardingUrl = process.env.REACT_APP_SELLER_ONBOARDING_URL || 'https://marketplace-seller-onboarding.vercel.app/onboard';
        window.location.href = onboardingUrl;
        return;
      }

      if (seller?.onboarding_status === 'COMPLETED' && seller?.paypal_merchant_id) {
        navigate('/sell');
      } else {
        const onboardingUrl = process.env.REACT_APP_SELLER_ONBOARDING_URL || 'https://marketplace-seller-onboarding.vercel.app/onboard';
        window.location.href = onboardingUrl;
      }
    } catch (error) {
      console.error('Error in handleSellProducts:', error);
      const onboardingUrl = process.env.REACT_APP_SELLER_ONBOARDING_URL || 'https://marketplace-seller-onboarding.vercel.app/onboard';
      window.location.href = onboardingUrl;
    }
  };

  const toggleProducts = () => {
    setIsProductsOpen(!isProductsOpen);
  };

  const handleEditProduct = (product) => {
    setEditingProduct(product);
  };

  const handleUpdateProduct = async (updatedProduct, oldImageUrl) => {
    try {
      const { error } = await supabase
        .from('sellables')
        .update({
          title: updatedProduct.title,
          description: updatedProduct.description,
          price: updatedProduct.price,
          product_image_url: updatedProduct.product_image_url
        })
        .eq('id', updatedProduct.id);

      if (error) throw error;

      if (oldImageUrl) {
        // Extract the file path from the old image URL
        const oldFilePath = oldImageUrl.split('/').pop();
        const { error: deleteError } = await supabase.storage
          .from('product-images')
          .remove([oldFilePath]);

        if (deleteError) {
          console.error('Error deleting old image:', deleteError);
        }
      }

      setProducts(products.map(p => p.id === updatedProduct.id ? updatedProduct : p));
      setEditingProduct(null);
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  const handleDeleteConfirmation = (product) => {
    setProductToDelete(product);
  };

  const handleDeleteProduct = async () => {
    if (productToDelete) {
      try {
        const { error } = await supabase
          .from('sellables')
          .delete()
          .eq('id', productToDelete.id);

        if (error) throw error;

        setProducts(products.filter(p => p.id !== productToDelete.id));
        setProductToDelete(null);
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    }
  };

  return (
    <>
      <div className="dashboard-header">
        <h2>Dashboard</h2>
        <div className="button-group">
          <button onClick={handleSellProducts} className="sell-products-button">
            {seller?.onboarding_status === 'COMPLETED' ? 'Sell Products' : 'Start Selling'}
          </button>
          <button onClick={handleAddGame} className="add-game-button">Add Games</button>
          <button onClick={handleLogout} className="logout-button">Log Out</button>
        </div>
      </div>
      <div className="games-dropdown">
        <div className="games-dropdown-header" onClick={toggleGames}>
          <h3>Games</h3>
          <span className={`arrow ${isGamesOpen ? 'open' : ''}`}>▼</span>
        </div>
        {isGamesOpen && (
          <div className="games-list">
            {games.length > 0 ? (
              games.map((game) => (
                <div key={game.id} className="game-row">
                  <img src={game.thumbnail_url || 'placeholder-image-url.jpg'} alt={game.game_info?.name || 'Game'} className="game-thumbnail" />
                  <div className="game-info">
                    <h4>{game.game_info?.name || 'Unnamed Game'}</h4>
                    <p>{truncateDescription(game.game_info?.description)}</p>
                  </div>
                  <div className="button-group">
                    <button className="refresh-button" onClick={() => handleRefreshDetails(game.id)}>Refresh Details</button>
                  </div>
                </div>
              ))
            ) : (
              <p className="no-games">No games connected</p>
            )}
          </div>
        )}
      </div>
      <div className="products-dropdown">
        <div className="products-dropdown-header" onClick={toggleProducts}>
          <h3>Products</h3>
          <span className={`arrow ${isProductsOpen ? 'open' : ''}`}>▼</span>
        </div>
        {isProductsOpen && (
          <div className="products-list">
            {products.length > 0 ? (
              products.map((product) => (
                <div key={product.id} className="product-row">
                  {editingProduct && editingProduct.id === product.id ? (
                    <EditProductForm
                      product={product}
                      onUpdate={handleUpdateProduct}
                      onCancel={() => setEditingProduct(null)}
                    />
                  ) : (
                    <>
                      <img src={product.product_image_url || 'placeholder-image-url.jpg'} alt={product.title} className="product-thumbnail" />
                      <div className="product-info">
                        <h4>
                          {product.title}
                          <span className="game-name">{product.game_name}</span>
                        </h4>
                        <p>{truncateDescription(product.description)}</p>
                        <p>Price: ${product.price}</p>
                      </div>
                      <div className="button-group">
                        <button className="edit-button" onClick={() => handleEditProduct(product)}>Edit</button>
                        <button className="delete-button" onClick={() => handleDeleteConfirmation(product)}>Delete</button>
                      </div>
                    </>
                  )}
                </div>
              ))
            ) : (
              <p className="no-products">No products uploaded</p>
            )}
          </div>
        )}
      </div>
      {productToDelete && (
        <div className="delete-confirmation-modal">
          <div className="delete-confirmation-content">
            <p>Are you sure you want to permanently delete this product?</p>
            <div className="button-group">
              <button onClick={handleDeleteProduct}>Yes, Delete</button>
              <button onClick={() => setProductToDelete(null)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard;