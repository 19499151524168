import React, { useState } from 'react';
import { supabase } from '../../utils/supabase';
import './Signup.css';

const robloxApiUrl = "https://roblox-api-proxy-10fn64vhn-blakes-projects-63357407.vercel.app";

function Signup() {
  const [email, setEmail] = useState('');
  const [robloxUsername, setRobloxUsername] = useState('');
  const [randomPhrase, setRandomPhrase] = useState('');
  const [userId, setUserId] = useState('');
  const [isUsernameConfirmed, setIsUsernameConfirmed] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [error, setError] = useState(null);
  const [password, setPassword] = useState('');

  const handleConfirmUsername = async () => {
    if (!robloxUsername || !email || !password) {
      setError('Please fill in all fields');
      return;
    }

    try {
      const response = await fetch(`${robloxApiUrl}/api/get-user-id?username=${encodeURIComponent(robloxUsername)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const userId = data.userId;

      const { data: existingUsers, error: checkError } = await supabase
        .from('phrases')
        .select('robloxUsername')
        .eq('robloxUsername', robloxUsername);

      if (checkError) throw checkError;

      if (existingUsers && existingUsers.length > 0) {
        window.location.href = 'https://store.obby.gg/login';
        return;
      }

      const newPhrase = await generateUniquePhrase();

      setRandomPhrase(newPhrase);
      setUserId(userId);
      setIsUsernameConfirmed(true);
    } catch (error) {
      console.error('Error confirming Roblox Username:', error);
      setError('Failed to fetch UserID or save data. Please try again.');
    }
  };

  const handleVerifyClick = async () => {
    if (!userId || !randomPhrase) {
      setError('Username or phrase is missing.');
      return;
    }

    setIsVerifying(true);
    setError(null);

    try {
      const response = await fetch(`${robloxApiUrl}/api/verify-bio`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          phrase: randomPhrase,
          robloxUsername,
        }),
      });

      const data = await response.json();

      if (data.verified) {
        // Attempt to sign up the user
        const { data: signUpData, error: signUpError } = await supabase.auth.signUp({
          email: email,
          password: password,
          options: {
            data: {
              roblox_username: robloxUsername,
              roblox_id: userId,
            }
          }
        });

        if (signUpError) {
          console.error('Signup error:', signUpError);
          if (signUpError.message.includes('email rate limit exceeded')) {
            setError('Too many signup attempts. Please try again later or contact support.');
          } else if (signUpError.message.includes('Email address cannot be used')) {
            setError('This email is not authorized for signup. Please contact support or use a different email.');
          } else if (signUpError.message.includes('User already registered')) {
            setError('This email is already registered. Please try logging in instead.');
          } else {
            setError(`Signup failed: ${signUpError.message}`);
          }
          return;
        }

        setIsVerified(true);

        // Insert the user data into the phrases table
        const { error: insertError } = await supabase
          .from('phrases')
          .insert([
            {
              robloxUsername: robloxUsername,
              phrase: randomPhrase,
              userid: userId,
              email: email,
              user_uid: signUpData.user.id,
              verified: true,
            },
          ]);

        if (insertError) throw insertError;

        // Inform the user that they need to confirm their email
        setError('Your account has been created. Please check your email to confirm your account before logging in.');
      } else {
        setIsVerified(false);
        setError('Verification failed. Please make sure the phrase is in your Roblox bio.');
      }
    } catch (error) {
      console.error('Error during verification:', error);
      setError('An error occurred during verification. Please try again later.');
    } finally {
      setIsVerifying(false);
    }
  };

  const handlePasswordSubmit = async () => {
    if (!password) {
      setError('Password is required.');
      return;
    }

    try {
      const { error: loginError } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
      });

      if (loginError) throw loginError;

      window.location.href = 'https://store.obby.gg/dashboard';
    } catch (error) {
      console.error('Error logging in:', error);
      setError(`An error occurred: ${error.message}`);
    }
  };

  const generateUniquePhrase = async (wordCount = 5) => {
    const wordBank = [
      "apple", "banana", "cherry", "dragon", "elephant", "flower", "guitar", "honey",
      "island", "jacket", "kitten", "lemon", "mountain", "night", "ocean", "quartz",
      "river", "sunshine", "tiger", "umbrella", "violin", "whale", "xylophone", "yogurt", "zebra"
    ];
    
    let phrase;
    let isUnique = false;

    while (!isUnique) {
      const words = new Set();
      while (words.size < wordCount) {
        const randomIndex = Math.floor(Math.random() * wordBank.length);
        words.add(wordBank[randomIndex]);
      }
      phrase = Array.from(words).join(" ");

      const { data, error } = await supabase
        .from('phrases')
        .select('phrase')
        .eq('phrase', phrase);

      if (error) {
        console.error('Error checking phrase uniqueness:', error);
        return null;
      }

      if (data.length === 0) {
        isUnique = true;
      }
    }

    return phrase;
  };

  return (
    <div className="signup-container">
      {error && <div className="error-message">{error}</div>}
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email"
        className="input-field"
      />
      <input
        type="text"
        value={robloxUsername}
        onChange={(e) => setRobloxUsername(e.target.value)}
        placeholder="Enter your Roblox username"
        className="input-field"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Enter your password"
        className="input-field"
      />
      <button onClick={handleConfirmUsername} disabled={isUsernameConfirmed} className="button">
        {isUsernameConfirmed ? 'Submitted' : 'Submit'}
      </button>

      {isUsernameConfirmed && (
        <>
          <div className="divider" />
          <p className="copy-text">Copy & paste the below phrase in your account bio.</p>
          <input
            type="text"
            value={randomPhrase}
            readOnly
            className="input-field"
          />
          <a href="https://www.roblox.com/users/profile" target="_blank" rel="noopener noreferrer" className="roblox-link">
            Find my Roblox profile page
          </a>

          <div className="divider" />
          <button onClick={handleVerifyClick} disabled={isVerified || isVerifying} className="button">
            {isVerified ? 'Account Created ✅' : isVerifying ? 'Verifying...' : 'Verify'}
          </button>

          {isVerified && (
            <>
              <div className="divider" />
              <button onClick={handlePasswordSubmit} className="button">
                Continue to Dashboard
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Signup;