import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSession } from '@supabase/auth-helpers-react';

const PrivateRoute = ({ children }) => {
  const session = useSession();
  const location = useLocation();

  console.log('PrivateRoute: Session status:', !!session);

  if (!session) {
    console.log('PrivateRoute: No session, redirecting to login');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  console.log('PrivateRoute: Session exists, rendering children');
  return children;
};

export default PrivateRoute;
