import React, { useState, useEffect } from 'react';
import { useSession } from '@supabase/auth-helpers-react';
import { useNavigate } from 'react-router-dom';
import './AddGame.css';

const API_URL = 'https://roblox-game-data-api.vercel.app/api/games';

function AddGame() {
    const [gameUrl, setGameUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [connected, setConnected] = useState(false);
    const [error, setError] = useState(null);
    const [showCreateRobloxWarning, setShowCreateRobloxWarning] = useState(false);
    const [groupVerification, setGroupVerification] = useState(null);
    const [isGroupVerificationShowing, setIsGroupVerificationShowing] = useState(false);
    
    const session = useSession();
    const navigate = useNavigate();

    useEffect(() => {
        if (!session) {
            navigate('/login');
        }
    }, [session, navigate]);

    useEffect(() => {
        setShowCreateRobloxWarning(gameUrl.includes("create.roblox.com"));
    }, [gameUrl]);

    async function fetchGameData() {
        if (!session) {
            setError("No user found. Please log in.");
            return;
        }

        if (!gameUrl) {
            setError("Please enter a Game URL");
            return;
        }
        setLoading(true);
        setError(null);
        setConnected(false);
        setGroupVerification(null);

        try {
            console.log("Fetching data for game URL:", gameUrl);
            const response = await fetch(`${API_URL}?identifier=${encodeURIComponent(gameUrl)}`, {
                headers: {
                    'Authorization': session.user.id
                }
            });
            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to fetch game data');
            }

            console.log("Fetched game data:", data);

            if (data.game_info.creator.type === 'User' && data.game_info.creator.id.toString() !== session.user.id) {
                setError("Only add games that you own.");
                return;
            }

            if (data.game_info.creator.type === 'Group') {
                const groupVerificationResult = await verifyGroupOwnership(data.game_info.creator.id);
                if (groupVerificationResult.needsVerification) {
                    setGroupVerification({
                        groupId: data.game_info.creator.id,
                        phrase: groupVerificationResult.phrase
                    });
                    setIsGroupVerificationShowing(true);
                    return;
                } else if (!groupVerificationResult.verified) {
                    setError("You don't have permission to add this game.");
                    return;
                }
            }

            // If we've reached this point, the user is authorized to add the game
            setConnected(true);
            // Here, you would typically call a function to store the game data
            // For example: await storeGameData(data);
        } catch (error) {
            console.error("Error fetching game data:", error);
            setError(`Failed to fetch game data: ${error.message}`);
        } finally {
            setLoading(false);
        }
    }

    async function verifyGroupOwnership(groupId) {
        try {
            const response = await fetch(`${API_URL}?action=verify-group&groupId=${groupId}&identifier=${encodeURIComponent(gameUrl)}`, {
                headers: {
                    'Authorization': session.user.id
                },
                credentials: 'include'
            });
            const data = await response.json();
            if (!response.ok) {
                console.error('Error response:', data);
                if (data.error === 'Group not found') {
                    setError("The specified group does not exist.");
                } else if (data.error === 'Group already verified by another user') {
                    setError("This group has already been verified by another user.");
                } else {
                    setError(data.error || `HTTP error! status: ${response.status}`);
                }
                return { needsVerification: false, verified: false };
            }
            if (data.verified) {
                setConnected(true);
                setGroupVerification(null);
                await storeGameData();
                return { needsVerification: false, verified: true };
            }
            return data;
        } catch (error) {
            console.error("Error verifying group ownership:", error);
            setError(`Failed to verify group ownership: ${error.message}`);
            return { needsVerification: false, verified: false };
        }
    }

    async function checkGroupVerification() {
        if (!groupVerification) return;

        try {
            const response = await fetch(`${API_URL}?action=check-group-verification&groupId=${groupVerification.groupId}&identifier=${encodeURIComponent(gameUrl)}`, {
                headers: {
                    'Authorization': session.user.id
                },
                credentials: 'include'
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.error || `HTTP error! status: ${response.status}`);
            }
            if (data.verified) {
                setConnected(true);
                setGroupVerification(null);
                await storeGameData();
            } else {
                setError("Group verification failed. Please make sure you've added the phrase to the group description.");
            }
        } catch (error) {
            console.error("Error checking group verification:", error);
            setError(`Failed to check group verification: ${error.message}`);
        }
    }

    async function storeGameData() {
        try {
            const response = await fetch(`${API_URL}?action=store-game-data&identifier=${encodeURIComponent(gameUrl)}`, {
                method: 'POST',
                headers: {
                    'Authorization': session.user.id,
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.error || `HTTP error! status: ${response.status}`);
            }
            console.log("Game data stored successfully");
        } catch (error) {
            console.error("Error storing game data:", error);
            setError(`Failed to store game data: ${error.message}`);
        }
    }

    return (
        <div className="add-game-container">
            <h2 className="add-game-title">Enter your Roblox Game URL</h2>
            <p className="add-game-subtitle">(roblox.com/games/gameid/gamename)</p>
            <input
                type="text"
                value={gameUrl}
                onChange={(e) => setGameUrl(e.target.value)}
                placeholder="Enter Game URL"
                className="input-field"
                disabled={isGroupVerificationShowing}
            />
            <button
                onClick={fetchGameData}
                disabled={loading || isGroupVerificationShowing}
                className="button"
            >
                {loading ? "Loading..." : "Add Game"}
            </button>
            {showCreateRobloxWarning && (
                <p className="warning-text">
                    Enter the roblox.com public game URL, not the create.roblox.com private URL.
                </p>
            )}
            {connected && (
                <>
                    <p className="success-text">Connected ✅</p>
                    <a href="/dashboard" className="dashboard-link">Back to Dashboard</a>
                </>
            )}
            {groupVerification && (
                <>
                    <div className="divider" />
                    <p className="group-verification-text">
                        Add the phrase to your group's description.
                    </p>
                    <input
                        type="text"
                        value={groupVerification.phrase}
                        readOnly
                        className="input-field"
                    />
                    <button
                        onClick={checkGroupVerification}
                        className="button"
                    >
                        Verify Group
                    </button>
                </>
            )}
            {error && <div className="error-message">{error}</div>}
        </div>
    );
}

export default AddGame;