import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import { supabase } from './utils/supabase';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import './App.css';
import AddGame from './components/AddGame/AddGame';
import Signup from './components/Signup/Signup';
import SellProduct from './components/SellProduct/SellProduct';

function App() {
  return (
    <SessionContextProvider supabaseClient={supabase}>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route 
              path="/dashboard" 
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              } 
            />
            <Route path="/" element={<Navigate to="/login" replace />} />
            <Route 
              path="/add-game" 
              element={
                <PrivateRoute>
                  <AddGame />
                </PrivateRoute>
              } 
            />
            <Route path="/signup" element={<Signup />} />
            <Route 
              path="/sell" 
              element={
                <PrivateRoute>
                  <SellProduct />
                </PrivateRoute>
              } 
            />
          </Routes>
        </div>
      </Router>
    </SessionContextProvider>
  );
}

export default App;
