import React, { useState, useEffect, useCallback } from 'react';
import { useSession } from '@supabase/auth-helpers-react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../utils/supabase';
import './SellProduct.css';

function SellProduct() {
  const [selectedGame, setSelectedGame] = useState('');
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [games, setGames] = useState([]);
  const [error, setError] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const session = useSession();
  const navigate = useNavigate();

  const fetchGames = useCallback(async () => {
    const { data, error } = await supabase
      .from('game_data')
      .select('id, game_info, game_id')
      .eq('user_uid', session.user.id);

    if (error) {
      console.error('Error fetching games:', error);
      setError('Failed to fetch games');
    } else {
      setGames(data);
    }
  }, [session]);

  useEffect(() => {
    if (!session) {
      navigate('/login');
    } else {
      fetchGames();
    }
  }, [session, navigate, fetchGames]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedGame || !name || !price || !description || !image) {
      setError('Please fill in all fields and upload an image');
      return;
    }

    try {
      const fileExt = image.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const { error: uploadError } = await supabase.storage
        .from('product-images')
        .upload(fileName, image);

      if (uploadError) throw uploadError;

      const { data: publicUrlData, error: urlError } = await supabase.storage
        .from('product-images')
        .getPublicUrl(fileName);

      if (urlError) throw urlError;

      const publicURL = publicUrlData.publicUrl;

      const selectedGameData = games.find(game => game.id.toString() === selectedGame);
      const gameId = selectedGameData ? selectedGameData.game_id : null;

      if (!gameId) {
        throw new Error('Invalid game selection');
      }

      const { error: insertError } = await supabase
        .from('sellables')
        .insert({
          game_id: gameId,
          title: name,
          price: price.toString(),
          description,
          product_image_url: publicURL,
          user_id: session.user.id,
          created_at: new Date().toISOString()
        });

      if (insertError) throw insertError;

      // Reset form and show success message
      setSelectedGame('');
      setName('');
      setPrice('');
      setDescription('');
      setImage(null);
      setError('Product created successfully!');
    } catch (error) {
      console.error('Error creating product:', error);
      setError('Failed to create product. Please try again.');
    }
  };

  return (
    <div className="sell-product-container">
      <h2 className="product-upload-header">Product Upload</h2>
      {!showForm ? (
        <div className="button-group vertical">
          <button className="create-button" onClick={() => setShowForm(true)}>Create Product</button>
          <div className="or-container">
            <div className="or-divider"></div>
            <span className="or-text">or</span>
            <div className="or-divider"></div>
          </div>
          <button className="import-button">Import Gamepasses</button>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="game-select">Select Game</label>
            <select
              id="game-select"
              value={selectedGame}
              onChange={(e) => setSelectedGame(e.target.value)}
              required
            >
              <option value="">Select a game</option>
              {games.map((game) => (
                <option key={game.id} value={game.id}>
                  {game.game_info.name}
                </option>
              ))}
            </select>
          </div>
          
          <div className="input-group">
            <label htmlFor="product-name">Product Name</label>
            <input
              id="product-name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter product name"
              required
            />
          </div>
          
          <div className="input-group">
            <label htmlFor="product-price">Price</label>
            <input
              id="product-price"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              placeholder="Enter price"
              required
            />
          </div>
          
          <div className="input-group">
            <label htmlFor="product-description">Description</label>
            <textarea
              id="product-description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter product description"
              required
            />
          </div>
          
          <div className="input-group">
            <label htmlFor="product-image">Upload Image</label>
            <input
              id="product-image"
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
              accept="image/*"
              required
            />
          </div>
          
          <button type="submit">Create Product</button>
        </form>
      )}
      {error && (
        <div className={error.includes('successfully') ? 'success-message' : 'error-message'}>
          <p>{error}</p>
          {error.includes('successfully') && (
            <a href="/dashboard" className="dashboard-link">Back to Dashboard</a>
          )}
        </div>
      )}
    </div>
  );
}

export default SellProduct;
